import { useState, useCallback, useMemo, useEffect } from "react";
import { useAppSelector, useAppDispatch } from "utils/redux";
import { toast } from "react-hot-toast";

import {
  DOCUMENT_TYPE,
  SovDataType,
  SovDataTypeDocument
} from "ts-types/DataTypes";
import {
  initiateDownload,
  PingDataGrid,
  SelectColumnNoSelectAll
} from "@repo/ping-react-js";
import { format, min, parseISO } from "date-fns";
import { useAuth } from "utils/hooks";
import { UnknownIcon, ExcelIcon, PdfIcon } from "./PVIcons";
import { setSelectDocuments } from "reducers/document";
import { PingActionsPanel } from "features/submission-dashboard/PingActionPanel";
import { useProcessFilesMutation } from "services/pvSlice";

import { SelectColumn } from "react-data-grid";

import "./PVAttachmentsTable.scss";

type RenderCellProps = { row: SovDataTypeDocument };

let toastId: string | null = null;

const PVAttachmentTable = ({ selectedItem }: { selectedItem: SovDataType }) => {
  const [processFiles, { isLoading }] = useProcessFilesMutation();
  const dispatch = useAppDispatch();
  const { accessToken } = useAuth();
  const [rows, setRow] = useState<SovDataTypeDocument[]>([]);
  const selectedDocuments = useAppSelector(
    (state) => state.document.selectedDocuments
  );

  const parseFiles = useCallback(async () => {
    if (!selectedItem.id || !selectedDocuments.length) {
      return;
    }

    await processFiles({
      id: selectedItem.id,
      filenames: selectedDocuments?.map((d) => d.filename)
    });

    if (toastId) {
      toast.remove(toastId);
    }
  }, [selectedItem, selectedDocuments, processFiles]);

  useEffect(() => {
    if (selectedItem?.documents) {
      setRow([
        ...selectedItem.documents.filter(
          (d) => d.document_type !== "EML" || !d.is_archived
        )
      ]);
    }
  }, [selectedItem]);

  useEffect(() => {
    if (selectedDocuments.length && !toastId) {
      toastId = toast.custom(
        () => (
          <PingActionsPanel
            classNames={isLoading ? "PingActionsPanel--disabled" : ""}
            label={
              isLoading
                ? "Submitting Files For Processing..."
                : "Process Selected Files"
            }
            key={selectedDocuments.length}
            onClick={() => parseFiles()}
          />
        ),
        {
          duration: Infinity,
          position: "top-center"
        }
      );
    }
  }, [parseFiles, isLoading, selectedDocuments]);

  const onClickDownload = useCallback(
    (attachment: { url: string; filename: string }) => {
      initiateDownload(
        import.meta.env.VITE_APP_ENV === "local"
          ? attachment.url.replace("8001", "80")
          : attachment.url,
        accessToken,
        attachment.filename
      );
    },
    [accessToken]
  );

  const onSelectedRowsChange = useCallback(
    (selectedRows: Set<number>) => {
      dispatch(
        setSelectDocuments(rows?.filter((r) => selectedRows?.has(r.id)))
      );
    },
    [rows, dispatch]
  );

  const columns = useMemo(() => {
    return [
      {
        ...SelectColumnNoSelectAll,
        renderCell: (props) =>
          ["SOV", "ACORD"].includes(props.row.document_type)
            ? SelectColumn?.renderCell?.(props)
            : null
      },
      {
        key: "file_icon",
        name: "",
        width: "2%",
        resizable: true,
        renderCell: (props: RenderCellProps) => {
          return (
            <>
              {["SOV", "SOVFIXER_SCRUBBER"].includes(
                props.row.document_type
              ) && <ExcelIcon onClick={() => onClickDownload(props.row)} />}
              {props.row.document_type === "ACORD" && (
                <PdfIcon onClick={() => onClickDownload(props.row)} />
              )}
              {!["SOV", "ACORD"].includes(props.row.document_type) && (
                <UnknownIcon onClick={() => onClickDownload(props.row)} />
              )}
            </>
          );
        }
      },
      {
        key: "created_time",
        name: "Date",
        resizable: true,
        width: "20%",
        renderCell: (props: RenderCellProps) => {
          if (!props.row.created_time) return "N/A";
          try {
            const date = parseISO(props.row.created_time);
            return format(date, "MM/dd/yyyy");
          } catch (error) {
            return "Invalid Date";
          }
        }
      },
      {
        key: "filename",
        name: "File Name",
        resizable: true,
        width: "20%",
        renderCell: (props: RenderCellProps) => {
          return props.row.filename;
        }
      },
      {
        key: "size",
        name: "File Size",
        resizable: true,
        renderCell: (props: RenderCellProps) => {
          return "TBD";
        }
      },
      {
        key: "document_type",
        name: "Type",
        resizable: true,
        width: "20%",
        renderCell: (props: RenderCellProps) => {
          return DOCUMENT_TYPE?.[props.row.document_type];
        }
      }
    ];
  }, [onClickDownload]);

  return (
    <>
      <PingDataGrid
        onSelectedRowsChange={onSelectedRowsChange}
        className="PVAttachmentTable__DataGrid"
        columns={columns}
        rows={rows}
        rowClass={() => "PVAttachmentTable__DataGrid__Row"}
        rowKeyGetter={(row: SovDataTypeDocument) => {
          return row.id || Math.random();
        }}
        rowHeight={30}
        headerRowHeight={30}
        style={{ overflow: "auto" }}
      />
    </>
  );
};

export default PVAttachmentTable;
