import { FC, useCallback, useState } from "react";
import { z } from "zod";
import { useForm, SubmitHandler } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";

import { PingButton } from "@repo/ping-react-js";

import {
  PVInputsColumn,
  PVInputsColumnsGrid
} from "features/submission-dashboard/PVFormsLayouts";
import { PVTextInputFormField } from "features/submission-dashboard/PVFormFields";
import { PVScrollSpySectionHeader } from "features/submission-dashboard/PVScrollSpy";
import { setServerErrors } from "features/submission-dashboard/set-server-errors";
import { useUpdateSubmissionTriageMutation } from "services/pvSlice";
import { SovDataType } from "ts-types/DataTypes";
import { emptyStringToNull } from "utils/zod-utils";

import "./PVBrokerInformationGrid.scss";

const formSchema = z.object({
  wholesaler: emptyStringToNull().optional(),
  broker_office: emptyStringToNull().optional(),
  broker_name: emptyStringToNull().optional(),
  assistant: emptyStringToNull().optional(),
  sender: emptyStringToNull().optional(),
  requested_uw: emptyStringToNull().optional(),
  broker_email: emptyStringToNull().optional(),
  broker_phone: emptyStringToNull().optional(),
  broker_license_number: emptyStringToNull().optional(),
  broker_license_state: emptyStringToNull().optional(),
  broker_license_expiry: emptyStringToNull().optional()
});

type FormInput = z.infer<typeof formSchema>;

type PVBrokerInformationGridProps = {
  selectedItem: SovDataType;
};

export const PVBrokerInformationGrid: FC<PVBrokerInformationGridProps> = ({
  selectedItem
}) => {
  const [isEditing, setIsEditing] = useState(false);

  const {
    register,
    handleSubmit,
    setError,
    reset,
    formState: { errors }
  } = useForm<FormInput>({ resolver: zodResolver(formSchema) });

  const [updateSubmission, { isLoading: isSubmitting }] =
    useUpdateSubmissionTriageMutation();

  const onSubmit: SubmitHandler<FormInput> = useCallback(
    async (data) => {
      const result = await updateSubmission({ id: selectedItem.id, data });
      if (!result.error) {
        setIsEditing(false);
        return;
      }

      setServerErrors(result.error.data, setError);
    },
    [updateSubmission, selectedItem.id, setError]
  );

  const onReset = useCallback(() => {
    reset();
    setIsEditing(false);
  }, [reset]);

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="PVBrokerInformationGrid">
      <PVScrollSpySectionHeader title="Broker Information">
        {isEditing ? (
          <>
            <PingButton
              label="Cancel"
              type="button"
              onClick={onReset}
              disabled={isSubmitting}
            />
            <PingButton
              label={isSubmitting ? "Saving …" : "Save"}
              type="submit"
              variant="primary"
              disabled={isSubmitting}
            />
          </>
        ) : (
          <PingButton
            label="Edit"
            type="button"
            onClick={() => setIsEditing(true)}
          />
        )}
      </PVScrollSpySectionHeader>

      <PVInputsColumnsGrid>
        {/* First Column */}
        <PVInputsColumn title="">
          <PVTextInputFormField
            label="Wholesaler"
            name="wholesaler"
            currentValue={"---"}
            register={register}
            error={errors.wholesaler}
            readOnly={true}
            isEditing={isEditing}
          />
          <PVTextInputFormField
            label="Office"
            name="broker_office"
            currentValue={"---"}
            register={register}
            error={errors.broker_office}
            readOnly={true}
            isEditing={isEditing}
          />
          <PVTextInputFormField
            label="Broker"
            name="broker_name"
            currentValue={selectedItem.broker_name || "---"}
            register={register}
            error={errors.broker_name}
            readOnly={isSubmitting}
            isEditing={isEditing}
          />
          <PVTextInputFormField
            label="Assistant"
            name="assistant"
            currentValue={"---"}
            register={register}
            error={errors.assistant}
            readOnly={true}
            isEditing={isEditing}
          />
          <PVTextInputFormField
            label="Sender"
            name="sender"
            currentValue={"---"}
            register={register}
            error={errors.sender}
            readOnly={true}
            isEditing={isEditing}
          />
          <PVTextInputFormField
            label="Requested UW"
            name="requested_uw"
            currentValue={"---"}
            register={register}
            error={errors.requested_uw}
            readOnly={true}
            isEditing={isEditing}
          />
        </PVInputsColumn>

        {/* Second Column */}
        <PVInputsColumn title="">
          <PVTextInputFormField
            label="Broker Email"
            name="broker_email"
            currentValue={selectedItem.broker_email?.toLowerCase() || "---"}
            register={register}
            error={errors.broker_email}
            readOnly={isSubmitting}
            isEditing={isEditing}
          />
          <PVTextInputFormField
            label="Broker Phone"
            name="broker_phone"
            currentValue={selectedItem.broker_phone || "---"}
            register={register}
            error={errors.broker_phone}
            readOnly={isSubmitting}
            isEditing={isEditing}
          />
          <PVTextInputFormField
            label="Broker Lic #"
            name="broker_lic_number"
            currentValue={selectedItem.broker_license_number || "---"}
            register={register}
            error={errors.broker_license_number}
            readOnly={isSubmitting}
            isEditing={isEditing}
          />
          <PVTextInputFormField
            label="Broker Lic State"
            name="broker_lic_state"
            currentValue={selectedItem.broker_license_state || "---"}
            register={register}
            error={errors.broker_license_state}
            readOnly={isSubmitting}
            isEditing={isEditing}
          />
          <PVTextInputFormField
            label="Broker Lic Expiry"
            name="broker_lic_expiry"
            currentValue={selectedItem.broker_license_expiry || "---"}
            register={register}
            error={errors.broker_license_expiry}
            readOnly={isSubmitting}
            isEditing={isEditing}
          />
        </PVInputsColumn>
      </PVInputsColumnsGrid>
    </form>
  );
};
