import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import omit from "lodash/omit";

import { baseQueryWithZodValidation } from "@repo/ping-react-js";

import { getApiBaseUrl } from "../utils";
import { addWorkflowUpdate } from "./workflowUpdatesSlice";
import {
  PING_VISION_DEFAULT_GRID_PAGE_SIZE,
  PING_VISION_DEFAULT_FIELDS,
} from "constants/ApiConstants";
import type { RootState } from "services/store";
import {
  type NavigationResponse,
  type ActivityItemType,
  type EmailCorrespondenceResponse,
  emailCorrespondenceResponseSchema,
} from "ts-types/ApiTypes";
import {
  SovDataTypePaginatedResponse,
  sovDataTypePaginatedResponseSchema,
  UserType,
} from "ts-types/DataTypes";

export const api = createApi({
  baseQuery: baseQueryWithZodValidation(
    fetchBaseQuery({
      baseUrl: getApiBaseUrl(),
      prepareHeaders: (headers, { getState, endpoint }) => {
        if (endpoint !== "uploadDocument") {
          headers.set("Content-Type", "application/json");
        }
        const state = getState() as RootState;
        const accessToken = state.auth.accessToken;
        if (accessToken && !headers.has("Authorization")) {
          headers.set("Authorization", `Bearer ${accessToken}`);
        }

        return headers;
      },
    })
  ),

  tagTypes: ["PVNotes", "TeamMembers", "PVSubmissionsList"],

  endpoints: (build) => ({
    /**
     * Get the current environment. The environment contains information about
     * the logged-in user as well as some general information.
     */
    getEnvironment: build.query<any, void>({
      query: () => ({
        url: `api/v1/environment`,
        method: "GET",
      }),
    }),

    /**
     * Get audit log for a single submission.
     */
    getSubmissionHistory: build.query<
      ActivityItemType[],
      { id: string; realTimeSubscriptions: Record<string, number[]> }
    >({
      query: ({ id }) => ({
        url: `api/v1/submission/${id}/history`,
        method: "GET",
      }),
      async onCacheEntryAdded(
        arg,
        { updateCachedData, cacheDataLoaded, cacheEntryRemoved, getState }
      ) {
        const state = getState() as RootState;
        const accessToken = state.auth?.accessToken;
        const subscriptions = arg.realTimeSubscriptions?.teams;

        if (!subscriptions.length) {
          return;
        }

        if (!accessToken) {
          console.error("No access token available");
          return;
        }

        const searchParams = new URLSearchParams();
        searchParams.append("token", accessToken);
        if (arg.realTimeSubscriptions?.teams) {
          searchParams.append(
            "team_ids",
            arg.realTimeSubscriptions?.teams.join(",")
          );
        }

        const ws = new WebSocket(
          `${import.meta.env.VITE_APP_WEBSOCKETS_CHANNEL}?${searchParams}`
        );

        ws.onopen = () => {
          ws.send(JSON.stringify({ type: "authenticate", token: accessToken }));
        };

        try {
          await cacheDataLoaded;

          ws.onmessage = (event) => {
            try {
              const message = JSON.parse(event.data);
              if (arg.id && arg.id !== message.data.id) return;
              // console.info("(hist)WebSocket message:", message);

              if (message.type === "submission.history.list") {
                const { data } = message.data;

                updateCachedData((draft) => {
                  return data;
                });
              }
            } catch (parseError) {
              console.error("Error parsing WebSocket message:", parseError);
            }
          };

          ws.onerror = (error) => {
            console.error("WebSocket error:", error);
          };
        } catch (error) {
          console.error("Error in cache handling or WebSocket setup:", error);
        }

        await cacheEntryRemoved;
        ws.close();
      },
    }),

    /**
     * Get a list of submissions. This endpoint supports infinite scroll.
     * Calling this endpoint also subscribes to real-time updates for the
     * specified subscriptions.
     *
     * Note that this endpoint cannot provide any tags. Invalidating the cache
     * for this endpoint will result in unpredictable behavior.
     */
    getSubmissions: build.query<
      SovDataTypePaginatedResponse,
      {
        id?: string | null;
        fields?: string[];
        realTimeSubscriptions: Record<string, number[]>;
        search?: string | null;
        advancedSearchFields?: Record<string, string> | null;
        cursorId?: string | null;
        orgShortName?: string | null;
      }
    >({
      async onCacheEntryAdded(
        arg,
        {
          updateCachedData,
          cacheDataLoaded,
          cacheEntryRemoved,
          getState,
          dispatch,
        }
      ) {
        const state = getState() as RootState;
        const accessToken = state.auth?.accessToken;
        const subscriptions = arg.realTimeSubscriptions?.teams;
        const submission_statuses = state.settings?.settings?.submission_status;

        if (!accessToken) {
          console.error("No access token available");
          return;
        }

        if (!subscriptions.length) {
          console.error("No subscriptions available");
          return;
        }

        const searchParams = new URLSearchParams();
        searchParams.append("token", accessToken);
        if (arg.realTimeSubscriptions?.teams) {
          searchParams.append(
            "team_ids",
            arg.realTimeSubscriptions?.teams.join(",")
          );
        }

        const ws = new WebSocket(
          `${import.meta.env.VITE_APP_WEBSOCKETS_CHANNEL}?${searchParams}`
        );

        ws.onopen = () => {
          ws.send(JSON.stringify({ type: "authenticate", token: accessToken }));
        };

        try {
          await cacheDataLoaded;

          ws.onmessage = (event) => {
            try {
              const message = JSON.parse(event.data);
              if (message.type === "submission.update") {
                let { changed_fields } = message.data;
                const { id, changed_documents, changed_jobs } = message.data;

                console.info("message.data", message.data);

                // DO NOT CHANGE: the backend does not send the client
                // workflow_status__name, so we need to query it and append it
                // to the Redux state manually. This avoids a query inside the
                // re-saved hook.
                if (changed_fields?.workflow_status_id) {
                  const workflow_status__name = submission_statuses?.find(
                    (s) => s.id === changed_fields.workflow_status_id
                  )?.name;
                  changed_fields = { ...changed_fields, workflow_status__name };
                }

                if (changed_fields?.workflow_status_id) {
                  dispatch(addWorkflowUpdate({
                    submissionId: id,
                    timestamp: Date.now(),
                    workflowStatusId: changed_fields.workflow_status_id,
                    changedById: changed_fields.status_changed_by_id,
                  }));
                }

                updateCachedData((draft) => {
                  const itemIndex = draft.results.findIndex(
                    (item) => item.id === id
                  );
                  if (itemIndex !== -1) {
                    draft.results[itemIndex] = {
                      ...draft.results[itemIndex],
                      ...changed_fields,
                    };
                    if (changed_documents?.length) {
                      changed_documents.forEach((newDocument) => {
                        const docIndex = draft.results[
                          itemIndex
                        ].documents.findIndex(
                          (doc) => doc.id === newDocument.id
                        );
                        if (docIndex !== -1) {
                          draft.results[itemIndex].documents[docIndex] =
                            newDocument;
                        } else {
                          draft.results[itemIndex].documents.push(newDocument);
                        }
                      });
                    }
                    if (changed_jobs?.length) {
                      changed_jobs.forEach((newJob) => {
                        const jobIndex = draft.results[
                          itemIndex
                        ].jobs.findIndex((doc) => doc.job_id === newJob.job_id);
                        if (jobIndex !== -1) {
                          draft.results[itemIndex].jobs[jobIndex] = newJob;
                        } else {
                          draft.results[itemIndex].jobs.push(newJob);
                        }
                      });
                    }
                  }
                });

                // JOE(TODO): we need to figure out a more intelligent way to do this,
                // but lets see how far this takes it before scott gets made
                // and notices.
                if (changed_fields?.workflow_status_id) {
                  // TODO: don't update the workflow status immediately.
                  // Instead, mark it as changed and show it in the UI using a
                  // different style. Ask Karthik for suggestions.
                }
              } else if (message.type === "submission.create") {
                const newSubmission = message.data;

                updateCachedData((draft) => {
                  if (
                    !draft.results.find((item) => item.id === newSubmission.id)
                  ) {
                    draft.results.unshift({ ...newSubmission.data });
                    draft.total_count += 1;
                  }
                });
              }
            } catch (parseError) {
              console.error("Error parsing WebSocket message:", parseError);
            }
          };

          ws.onerror = (error) => {
            console.error("WebSocket error:", error);
          };
        } catch (error) {
          console.error("Error in cache handling or WebSocket setup:", error);
        }

        await cacheEntryRemoved;
        ws.close();
      },

      query: ({ fields, search, advancedSearchFields, cursorId }) => {
        const searchParams = new URLSearchParams();

        if (cursorId) {
          searchParams.append("cursor_id", cursorId);
        }

        searchParams.append(
          "page_size",
          PING_VISION_DEFAULT_GRID_PAGE_SIZE.toString()
        );

        searchParams.append("fields", fields?.join(",") || "");

        if (search) {
          searchParams.append("search", search);
        }

        if (advancedSearchFields) {
          Object.entries(advancedSearchFields).forEach(([key, value]) => {
            searchParams.append(key, value);
          });
        }

        return {
          url: `api/v1/submission?${searchParams.toString()}`,
          method: "GET",
        };
      },

      providesTags: () => [{ type: "PVSubmissionsList", id: "LIST" }],

      serializeQueryArgs: ({ endpointName, queryArgs }) => {
        // We create a custom cache key for this query because we're using
        // infinite scroll. By default, RTK Query replaces the results of the
        // query in the cache with new results every time any of the query args
        // change. In our case, we want to ignore the cursorId when determining
        // the cache key, so that we can keep appending new results to the cache
        // instead of replacing them.
        const queryArgsWithoutCursorId = omit(queryArgs, "cursorId");
        return {
          endpointName,
          ...queryArgsWithoutCursorId,
        };
      },

      merge: (existing, incoming, { arg }) => {
        // Merge incoming cache data with existing cache data. Because we use
        // infinite scroll for this endpoint, we nee to make sure we append
        // incoming results to existing results instead of throwing them away.

        // If we don't have any data, or if the cursor ID is null (indicating an
        // initial request), return the incoming data.
        if (!existing || !arg.cursorId) {
          return incoming;
        }

        // Merge incoming and existing data.
        return {
          ...incoming,
          results: [...existing.results, ...incoming.results],
        };
      },

      forceRefetch({ currentArg, previousArg }) {
        // Because we removed `cursorId` from the cache key, we need to take it
        // into account here when checking if we should make a new request.
        return currentArg?.cursorId !== previousArg?.cursorId;
      },

      // TODO: uncomment this so we can start doing strict schema checkings again.
      // extraOptions: {
      //   dataSchema: sovDataTypePaginatedResponseSchema
      // }
    }),

    /**
     * Get a list of submissions. This endpoint does not support infinite
     * scroll. It also does not create any WebSocket subscriptions.
     */
    getNotRealTimeSubmissions: build.query<
      SovDataTypePaginatedResponse,
      { cursor_id?: string; search?: string; limit?: number }
    >({
      query: ({ cursor_id, search, limit = 100 }) => {
        const params: Record<string, string | boolean | number> = {
          page_size: limit,
          fields: PING_VISION_DEFAULT_FIELDS.join(","),
        };

        if (search) {
          params.search = search;
        }

        if (cursor_id) {
          params.cursor_id = cursor_id;
        }

        return {
          url: `api/v1/submission`,
          method: "GET",
          params,
        };
      },
      extraOptions: {
        dataSchema: sovDataTypePaginatedResponseSchema,
      },
      keepUnusedDataFor: 0, // Don't keep the data in cache
      forceRefetch: () => {
        return true; // Always refetch when the query is called
      },
    }),

    /**
     * Upload a document for a submission.
     *
     * TODO: uploading a document should result in a WebSocket message that
     * updates the submission in-place.
     */
    uploadDocument: build.mutation({
      query: ({ accessToken, id, file }) => ({
        url: `api/v1/submission/${id}/document`,
        method: "POST",
        body: file,
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }),
    }),

    /**
     * Bulk update the status of multiple submissions.
     *
     * TODO: bulk updating submissions should result in a WebSocket message that
     * updates the submissions in-place.
     */
    bulkUpdateSubmission: build.mutation({
      query: ({ ids, changes }) => ({
        url: `api/v1/submission/bulkupdate`,
        method: "POST",
        body: { ids, changes },
      }),
    }),

    /**
     * Change the triage status of a submission.
     *
     * TODO: changing the triage status should result in a WebSocket message that
     * updates the submission in-place.
     */
    changeSubmissionTriageStatus: build.mutation<
      any,
      { id: string; status: string }
    >({
      query: ({ id, status }) => ({
        url: `api/v1/submission/${id}/change_status/`,
        method: "PATCH",
        body: { workflow_status_id: status },
      }),
    }),

    /**
     * Mark a submission as a duplicate of another submission.
     *
     * TODO: marking a submission as a duplicate should result in a WebSocket
     * message that updates the submission in-place.
     */
    markSubmissionAsDuplicate: build.mutation<
      any,
      { id: string; duplicate_of_submission_id: string }
    >({
      query: ({ id, duplicate_of_submission_id }) => ({
        url: `api/v1/submission/${id}/mark_as_duplicate/`,
        method: "PATCH",
        body: { duplicate_of_submission_id: duplicate_of_submission_id },
      }),
    }),

    /**
     * Update an attachment for a submission. Used for renaming, changing
     * document type, and archiving.
     *
     * TODO: updating an attachment should result in a WebSocket message that
     * updates the attachment in-place.
     */
    updateSubmissionDocument: build.mutation<
      any,
      { id: string; filename: string; data: Record<string, any> }
    >({
      query: ({ id, filename, data }) => ({
        url: `api/v1/submission/${id}/document/${filename}`,
        method: "PATCH",
        body: data,
      }),
    }),

    /**
     * Manually ask SOVFixer to parse an SOV file attached to a submission.
     *
     * TODO: manually asking SOVFixer to parse an SOV file should result in a
     * WebSocket message that updates the attachment in-place.
     */
    parseSovFile: build.mutation<any, { id: string; filename: string }>({
      query: ({ id, filename }) => ({
        url: `api/v1/submission/${id}/document/${filename}/sovfixer-parse`,
        method: "POST",
      }),
    }),

    /**
     * Manually ask SOVFixer to parse all SOV files attached to a submission.
     *
     * TODO: manually asking SOVFixer to parse all SOV files should result in a
     * WebSocket message that updates the attachments in-place.
     */
    processFiles: build.mutation<any, { id: string; filenames: string[] }>({
      query: ({ id, filenames }) => ({
        url: `api/v1/submission/${id}/sovfixer-parse`,
        method: "POST",
        body: { filenames },
      }),
    }),

    /**
     * Update the triage status of a submission.
     *
     * TODO: updating the triage status of a submission should result in a
     * WebSocket message that updates the submission in-place.
     */
    updateSubmissionTriage: build.mutation<
      any,
      { id: string; data: Record<string, any> }
    >({
      query: ({ id, data }) => ({
        url: `api/v1/submission/${id}/`,
        method: "PATCH",
        body: data,
      }),
    }),

    /**
     * Get the current user's teams.
     */
    getUserTeams: build.query<
      { id: number; name: string; membership_type: string; team_id: number }[],
      void
    >({
      query: () => ({
        url: `api/v1/user/teams/`,
        method: "GET",
      }),
    }),

    /**
     * Get the navigation items for the current user. Used to populate the left
     * sidebar.
     */
    getNav: build.query<NavigationResponse, any>({
      query: () => ({
        url: `api/v1/nav`,
        method: "GET",
      }),
    }),

    /**
     * Get the current user's settings. This includes the user's teams, their
     * profile, and the submission statuses available to them.
     */
    getSettings: build.query<any, any>({
      query: () => ({
        url: `api/v1/settings`,
        method: "GET",
      }),
    }),

    /**
     * Get the email correspondence for a submission. This returns an email
     * thread that we can render in the documents panel.
     */
    getEmailCorrespondence: build.query<
      EmailCorrespondenceResponse,
      { sovid: string }
    >({
      query: ({ sovid }) => ({
        url: `api/v1/submission/${sovid}/correspondence`,
      }),
      extraOptions: {
        dataSchema: emailCorrespondenceResponseSchema,
      },
    }),

    /**
     * Create a note for a submission.
     */
    createNote: build.mutation({
      query: ({ id, text }) => ({
        url: `api/v1/submission/note/`,
        method: "POST",
        body: { submission: id, text: text },
      }),
      invalidatesTags: ["PVNotes"],
    }),

    /**
     * Get the members of a team.
     */
    getTeamMembers: build.query<UserType[], number>({
      query: (teamId) => ({
        url: `api/v1/memberships/?team_id=${teamId}`,
        method: "GET",
      }),
      providesTags: ["TeamMembers"],
    }),

    /**
     * Add a member to a team.
     */
    createTeamMember: build.mutation<
      UserType,
      {
        teamId: string;
        first_name: string;
        last_name: string;
        user_email: string;
        membership_type: string;
      }
    >({
      query: ({ teamId, ...body }) => ({
        url: `api/v1/memberships/`,
        method: "POST",
        body: {
          team_id: teamId,
          ...body,
        },
      }),
      invalidatesTags: ["TeamMembers"],
    }),

    /**
     * Delete a member from a team.
     */
    deleteTeamMember: build.mutation<void, { membershipId: number }>({
      query: ({ membershipId }) => ({
        url: `api/v1/memberships/${membershipId}/`,
        method: "DELETE",
      }),
      invalidatesTags: ["TeamMembers"],
    }),

    /**
     * Update a member's role in a team.
     */
    updateTeamMember: build.mutation<
      any,
      {
        membershipId: number;
        membership_type: string;
        user_email: string;
      }
    >({
      query: ({ membershipId, membership_type, user_email }) => {
        return {
          url: `api/v1/memberships/${membershipId}/`,
          method: "PATCH",
          body: {
            membership_type,
            user_email,
          },
        };
      },
      invalidatesTags: ["TeamMembers"],
    }),
  }),
});

export const {
  useCreateNoteMutation,
  useBulkUpdateSubmissionMutation,
  useGetNavQuery,
  useUploadDocumentMutation,
  useGetSubmissionHistoryQuery,
  useGetSubmissionsQuery,
  useGetNotRealTimeSubmissionsQuery,
  useChangeSubmissionTriageStatusMutation,
  useMarkSubmissionAsDuplicateMutation,
  useUpdateSubmissionTriageMutation,
  useGetEnvironmentQuery,
  useGetSettingsQuery,
  useUpdateSubmissionDocumentMutation,
  useParseSovFileMutation,
  useProcessFilesMutation,
  useGetEmailCorrespondenceQuery,
  useGetTeamMembersQuery,
  useCreateTeamMemberMutation,
  useDeleteTeamMemberMutation,
  useGetUserTeamsQuery,
  useUpdateTeamMemberMutation,
} = api;
