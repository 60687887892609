import { useState, useCallback, useMemo } from "react";
import cx from "classnames";
import { useHistory } from "react-router-dom";
import lockr from "lockr";
import slugify from "slugify";
import { PingLucideIcon, useQuery } from "@repo/ping-react-js";
import { useAppSelector, useAppDispatch } from "utils/redux";
import { FRONT_END_BASE_URL } from "constants/index";
import { useSlug, useTeamId } from "utils/hooks";
import { usePanelContext } from "utils/context";
import type {
  CategoryView,
  NavigationItem,
} from "@repo/ping-react-js/src/ts-types/ApiTypes";
import { useGetUserTeamsQuery, api } from "services/pvSlice";
import { setCurrentPanelContent } from "reducers/settings";
import { setCurrentCursorId } from "reducers/inbox";
import { clearWorkflowUpdates } from "services/workflowUpdatesSlice";

import "./PingVisionSidebar.scss";

const ExpandIcon = () => (
  <PingLucideIcon
    className="PingVisionSidebarSection__Header__Icon"
    iconName={"ChevronRight"}
  />
);

const PingVisionSidebarSection = ({
  name,
  icon,
  items = [],
  ...rest
}: CategoryView) => {
  const [expand, setExpand] = useState(lockr.get(name, true));
  const history = useHistory();
  const teamId = useTeamId();
  const slug = useSlug();
  const dispatch = useAppDispatch();
  const searchParams = useQuery();
  const pathname = window.location.pathname;
  const isTeamManagementView = pathname.endsWith("/team-members");

  const handleClick = useCallback(
    (item: CategoryView, event: React.MouseEvent) => {
      event.preventDefault();

      // Make sure the new page will refresh the data.
      dispatch(setCurrentCursorId(null));

      // Clear both the submissions list and status workflow updates
      dispatch(api.util.invalidateTags(["PVSubmissionsList"]));
      dispatch(clearWorkflowUpdates());

      if (item.slug?.includes("team-members")) {
        dispatch(
          setCurrentPanelContent({
            type: "team-management",
            teamId: item.team_id?.toString(),
          }),
        );

        // Update URL with clean team management path
        const newPath = `/${slugify(item.team_name)}/team-members`;
        history.replace(`${newPath}?teamId=${item.team_id}`);
      } else {
        const path = item.team_name
          ? `${FRONT_END_BASE_URL}/${slugify(item.team_name)}/views/${item.slug}`
          : `${FRONT_END_BASE_URL}/${item.slug}`;
        history.push(path);
      }
    },
    [history, dispatch],
  );

  const isSelected = useCallback(
    (item: CategoryView): boolean => {
      if (isTeamManagementView && item.slug?.includes("team-members")) {
        const currentTeamId = searchParams.get("teamId");
        return currentTeamId === item.team_id?.toString();
      } else if (!isTeamManagementView && item.team_id) {
        return item.slug === slug && slugify(item.team_name || "") === teamId;
      }
      return !isTeamManagementView && item.slug === slug;
    },
    [slug, teamId, isTeamManagementView, searchParams],
  );

  const hasItems = items && items?.length > 0;
  const isNestedSection = hasItems && items[0].items;

  if (isNestedSection) {
    return (
      <div className="PingVisionSidebarSection">
        <div
          className={cx("PingVisionSidebarSection__Header")}
          onClick={() => {
            lockr.set(name, !expand);
            setExpand(!expand);
          }}
        >
          <ExpandIcon expand={expand} />
          {name}
        </div>
        {expand && (
          <ul className="PingVisionSidebarSection__List">
            {items.map((item: CategoryView, i: number) => (
              <PingVisionSidebarSection key={i} {...item} />
            ))}
          </ul>
        )}
      </div>
    );
  }

  if (hasItems) {
    return (
      <div className="PingVisionSidebarSection">
        <div
          className={cx("PingVisionSidebarSection__Header", {
            "PingVisionSidebarSection__Header--Nested": hasItems,
          })}
          onClick={() => {
            lockr.set(name, !expand);
            setExpand(!expand);
          }}
        >
          <ExpandIcon expand={expand} />
          {name}
        </div>
        {expand && (
          <ul className="PingVisionSidebarSection__List">
            {items?.map((item: CategoryView, i: number) => {
              const isActive = isSelected(item);
              return (
                <li
                  key={i}
                  className={cx("PingVisionSidebarSection__List__Item", {
                    "PingVisionSidebarSection__List__Item--Active": isActive,
                  })}
                >
                  <a
                    href="#"
                    key={isActive.toString()}
                    onClick={(e) => !isActive && handleClick(item, e)}
                    className={cx(
                      "PingVisionSidebarSection__List__Item__Link",
                      {
                        "PingVisionSidebarSection__List__Item__Link--Active":
                          isActive,
                      },
                    )}
                  >
                    <PingLucideIcon
                      className={cx(
                        "PingVisionSidebarSection__List__Item__Link__Icon",
                      )}
                      iconName={item.icon}
                    />
                    <span>{item.name}</span>
                  </a>
                </li>
              );
            })}
          </ul>
        )}
      </div>
    );
  }

  return (
    <div className="PingVisionSidebarSection">
      <div
        key={isSelected(rest).toString()}
        className={cx("PingVisionSidebarSection__Header", {
          "PingVisionSidebarSection__Header--Active": isSelected(rest),
          "PingVisionSidebarSection__Header--TeamMembers":
            rest.slug?.includes("team-members"),
        })}
        onClick={(e) => handleClick(rest, e)}
      >
        <PingLucideIcon
          size={16}
          className={cx("PingVisionSidebarSection__Header__Icon")}
          iconName={icon}
        />
        {name}
      </div>
    </div>
  );
};

export const PingVisionSidebar = () => {
  const nav = useAppSelector((state) => state.settings.nav);
  const { isLeftNavVisible } = usePanelContext();

  // Get all teams for the current user with their membership types
  const { data: userTeams } = useGetUserTeamsQuery();

  const adminTeams = userTeams?.filter((team) => {
    const membershipType = team.membership_type?.toLowerCase();
    return membershipType === "owner" || membershipType === "admin";
  });

  // Add team settings section if it doesn't exist
  const navWithTeamSettings = useMemo(() => {
    if (!adminTeams?.length) {
      return nav;
    }

    const teamSettings: NavigationItem = {
      name: "Manage Team Members",
      icon: "Settings",
      items:
        nav?.views?.[1]?.items
          ?.filter((item) => {
            const teamId = item?.items?.[0]?.team_id;
            // Compare nav team_id with team.id from the API response
            return adminTeams.some(
              (team) => team.id?.toString() === teamId?.toString(),
            );
          })
          ?.map((item) => ({
            name: `${item?.items?.[0]?.team_name}`,
            icon: "Users",
            team_name: item?.items?.[0]?.team_name || "",
            team_id: item?.items?.[0]?.team_id || "",
            items: [],
            slug: `team-members?teamId=${item?.items?.[0]?.team_id || ""}`,
            teamId: item?.items?.[0]?.team_id || "",
          })) || [],
    };

    return {
      ...nav,
      views: [...(nav?.views || []), teamSettings],
    };
  }, [nav, adminTeams]);

  const sidebarElt = (
    <nav
      className={cx("PingVisionSidebar", {
        "PingVisionSidebar--Collapsed": !isLeftNavVisible,
      })}
    >
      {navWithTeamSettings?.views?.map((section, i) => {
        const categoryView: CategoryView = {
          name: section.name,
          icon: section.icon,
          items: section.items || [],
          ...(section.team_name && { team_name: section.team_name }),
          ...(section.team_id && { team_id: section.team_id }),
          ...(section.slug && { slug: section.slug }),
        };
        return <PingVisionSidebarSection key={i} {...categoryView} />;
      })}
    </nav>
  );

  return sidebarElt;
};
