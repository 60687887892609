import { z } from "zod";

import type { LucideIconName } from "@repo/ping-react-js";

import { UserType, TeamType, SubmissionStatusType } from "./DataTypes";

export type SettingsResponseType = {
  teams: TeamType[];
  submission_status: SubmissionStatusType[];
  users: UserType[];
};

export type NavigationResponse = {
  views: NavigationItem[];
};

export type CategoryView = {
  name: string;
  items: NavigationItem[];
  icon: LucideIconName;
  team_name?: string;
  team_id?: string | number;
  slug?: string;
};
export type NavigationItem = CategoryView;

export type View = {
  slug?: string;
  name: string;
  view_type?: "TRIAGE" | "LIST";
  description?: string;
  filters?: Record<string, string | number>;
  group_by?: string;
  order_by?: string;
  icon: LucideIconName;
  items?: View[];
  team_name?: string;
  team_id?: number | string;
};

export type ActivityItemType = {
  id: number;
  cause: string;
  action: number;
  actor_id: null | number;
  actor_username: null | string;
  timestamp: string;
  object_id: number;
  messages: string[] | Record<string, string>[];
  verb: string;
  avatar?: string;
  name?: string;
};

const emailCorrespondenceResponseItemSchema = z
  .object({
    id: z.number(),
    submission_id: z.number(),
    subject: z.string().nullable(),
    html: z.string().nullable(),
    from_address: z.string().nullable(),
    to_addresses: z.string().nullable(),
    cc_addresses: z.string().or(z.array(z.string())).nullable(),
    source_email_time: z.string().datetime().nullable(),
  })
  .strict();

export const emailCorrespondenceResponseSchema = z.array(
  emailCorrespondenceResponseItemSchema,
);

export type EmailCorrespondenceResponseItem = z.infer<
  typeof emailCorrespondenceResponseItemSchema
>;

export type EmailCorrespondenceResponse = z.infer<
  typeof emailCorrespondenceResponseSchema
>;
