import { FC, useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useHotkeys } from "react-hotkeys-hook";
import { useMeasure } from "@uidotdev/usehooks";

import {
  LoadingMessagePanel,
  PingVirtualWindow,
  useAuth,
} from "@repo/ping-react-js";

import { PingVisionSubmissionListItem } from "features/submission-dashboard/PingVisionSubmissionListItem";
import {
  updateMultiSelectToast,
  clearMultiSelectToast,
} from "features/submission-dashboard/PingMultiSelectOptions";
import { useAppDispatch, useAppSelector } from "utils/redux";
import { usePingId, useSlug, useTeamId } from "utils/hooks";
import {
  setIsCommandMenuOpened,
  setSelectedSubmissions,
} from "reducers/settings";
import { setCurrentCursorId, setSlug, setTeamId } from "reducers/inbox";
import { usePingVisionUrlStore } from "features/usePingVisionUrlStore";
import { APP_MODES } from "constants/SubmissionConstants";
import { useGetSubmissionList } from "features/submission-dashboard/queries";
import { SovDataType } from "ts-types/DataTypes";

import "./PingVisionSubmissionList.scss";

const hasWorkflowMismatch = (
  workflowStatusId: number,
  inboxSlug: string,
): boolean => {
  if (
    (workflowStatusId === 1 ||
      workflowStatusId === 2 ||
      workflowStatusId === 3) &&
    inboxSlug !== "inbox" &&
    inboxSlug !== "all"
  ) {
    return true;
  }
  if (
    workflowStatusId === 4 &&
    inboxSlug !== "in-progress" &&
    inboxSlug !== "all"
  ) {
    return true;
  }
  if (
    workflowStatusId === 5 &&
    inboxSlug !== "completed" &&
    inboxSlug !== "all"
  ) {
    return true;
  }
  if (workflowStatusId === 6 && inboxSlug !== "closed" && inboxSlug !== "all") {
    return true;
  }
  return false;
};

export const PingVisionSubmissionList: FC = () => {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const { setMode } = usePingVisionUrlStore();

  const selectedItemId = usePingId();

  const sovs: SovDataType[] = useAppSelector((state) => state.inbox.sovs);
  const inboxSlug = useAppSelector((state) => state.inbox.slug);
  const { ssoUser } = useAuth();
  const workflowUpdates = useAppSelector(
    (state) => state.workflowUpdates.recentUpdates,
  );

  const { data: sovData, isFetching: isFetchingSovData } =
    useGetSubmissionList();

  // Create a map of submissionId to workflow update outside the filter
  const workflowUpdateMap = workflowUpdates.reduce(
    (acc, update) => {
      acc[update.submissionId] = update;
      return acc;
    },
    {} as Record<string, (typeof workflowUpdates)[0]>,
  );

  // Filter out submissions where the user was the last one to change the workflow status
  const filteredSovs = sovs.filter((submission) => {
    const workflowUpdate = workflowUpdateMap[submission.id];
    return !workflowUpdate || workflowUpdate.changedById !== ssoUser?.userId;
  });

  const nextCursorId = sovData?.cursor_id;

  const [selectedItems, setSelectedItems] = useState<Set<string>>(new Set());
  const [isShiftDown, setIsShiftDown] = useState<boolean>(false);
  const [cleared, setCleared] = useState<number>(0);
  const [lastSelectedItem, setLastSelectedItem] = useState<string | null>(null);

  const slug = useSlug();
  const teamId = useTeamId();

  useEffect(() => {
    if (slug) {
      dispatch(setSlug(slug));
    }
  }, [dispatch, slug]);

  useEffect(() => {
    if (teamId) {
      dispatch(setTeamId(teamId));
    }
  }, [dispatch, teamId]);

  useEffect(() => {
    if (selectedItems.size) {
      dispatch(setSelectedSubmissions([...selectedItems]));
      updateMultiSelectToast(
        selectedItems,
        () => {
          setSelectedItems(new Set());
          setCleared(Math.random());
        },
        () => {
          dispatch(setIsCommandMenuOpened(true));
        },
        () => {},
      );
    } else {
      clearMultiSelectToast();
      dispatch(setSelectedSubmissions([]));
    }
  }, [selectedItems, dispatch]);

  const handleCheckboxChange = useCallback(
    (id: string, checked: boolean) => {
      setSelectedItems((prevSelected) => {
        const newSelected = new Set(prevSelected);

        if (isShiftDown && lastSelectedItem && checked) {
          const startIndex = sovs.findIndex(
            (sov) => sov.id === lastSelectedItem,
          );
          const endIndex = sovs.findIndex((sov) => sov.id === id);
          const [lower, upper] = [startIndex, endIndex].sort((a, b) => a - b);

          for (let i = lower; i <= upper; i++) {
            newSelected.add(sovs[i].id);
          }
        } else {
          if (checked) {
            newSelected.add(id);
          } else {
            newSelected.delete(id);
          }
        }

        setLastSelectedItem(checked ? id : null);
        return newSelected;
      });
    },
    [isShiftDown, lastSelectedItem, sovs],
  );

  const onClickIncomingItem = useCallback(
    (id: string) => {
      if (!teamId && !slug) {
        return;
      }
      if (isShiftDown) {
        handleCheckboxChange(id, !selectedItems.has(id));
        return;
      }

      // const useNewUrl = true;

      const currentSearchParams = new URLSearchParams({ selected: id });
      history.push(`${window.location.pathname}?${currentSearchParams}`);

      // const currentSearchParams = new URLSearchParams(window.location.search);
      // const newUrl = `${FRONT_END_BASE_URL}/i/${id}`;
      // const searchString = currentSearchParams.toString();
      // const finalUrl = searchString ? `${newUrl}?${searchString}` : newUrl;
      // history.push(finalUrl);
    },
    [isShiftDown, teamId, history, slug, handleCheckboxChange, selectedItems],
  );

  const handleDoubleClickItem = useCallback(() => {
    if (isShiftDown) {
      return;
    }
    setMode(APP_MODES.DETAIL);
  }, [setMode, isShiftDown]);

  const currentIndex = sovs?.findIndex((s) => s.id === selectedItemId);

  const selectNextSov = useCallback(() => {
    if (currentIndex !== undefined && currentIndex < sovs.length - 1) {
      const nextSov = sovs[currentIndex + 1];
      onClickIncomingItem(nextSov.id);
    }
  }, [currentIndex, sovs, onClickIncomingItem]);

  const selectPreviousSov = useCallback(() => {
    if (currentIndex !== undefined && currentIndex > 0) {
      const previousSov = sovs[currentIndex - 1];
      onClickIncomingItem(previousSov.id);
    }
  }, [currentIndex, sovs, onClickIncomingItem]);

  useHotkeys("down", selectNextSov, [selectNextSov]);
  useHotkeys("up", selectPreviousSov, [selectPreviousSov]);
  useHotkeys("esc", () => {
    setSelectedItems(new Set());
    setLastSelectedItem(null);
  });

  useHotkeys(
    "shift",
    () => {
      setIsShiftDown(true);
    },
    { keyup: false, enableOnFormTags: ["input", "select", "textarea"] },
  );

  useHotkeys(
    "shift",
    () => {
      setIsShiftDown(false);
    },
    { keyup: true, enableOnFormTags: ["input", "select", "textarea"] },
  );

  const [submissionListRef, { height: submissionListHeight }] = useMeasure();

  return (
    <ul
      className="PingVisionSubmissionList"
      ref={submissionListRef}
      key={cleared}
    >
      {submissionListHeight && (
        <PingVirtualWindow
          windowHeight={submissionListHeight}
          items={filteredSovs}
          renderItem={(item: SovDataType) => {
            const key = `sov-${item.id}-${selectedItems.has(item.id)}`;
            // if a submission item whose workflow status was changed by another user is still in the list, demarcate that visually
            const hasMismatch =
              !isFetchingSovData &&
              inboxSlug &&
              item?.workflow_status_id &&
              hasWorkflowMismatch(item?.workflow_status_id, inboxSlug);

            return (
              <PingVisionSubmissionListItem
                itemIsChecked={selectedItems.has(item.id)}
                key={key}
                sov={item}
                isSelected={selectedItemId === item.id}
                onClickIncomingItem={onClickIncomingItem}
                onCheckboxChange={handleCheckboxChange}
                onDoubleClickItem={handleDoubleClickItem}
                className={hasMismatch ? "workflow-status-mismatch" : ""}
              />
            );
          }}
          onEndReached={() => {
            if (nextCursorId && !isFetchingSovData) {
              dispatch(setCurrentCursorId(nextCursorId));
            }

            return Promise.resolve();
          }}
        />
      )}
      {isFetchingSovData && <LoadingMessagePanel />}
    </ul>
  );
};
