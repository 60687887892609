import { useState, useEffect } from "react";
import { SovDataType, DOCUMENT_PROCESSING_STATUS } from "ts-types/DataTypes";
import { PingDataGrid, PingLucideIcon } from "@repo/ping-react-js";
import { format, parseISO } from "date-fns";

const ProgressBar: React.FC<{ percentage: number }> = ({ percentage }) => {
  return (
    <div className="PVAttachmentTable__DataGrid__Progress_Container">
      <div
        className="PVAttachmentTable__DataGrid__Progress_Bar"
        style={{ width: `${percentage}%` }}
      />
    </div>
  );
};

const PVJobsTable = ({ selectedItem }: { selectedItem: SovDataType }) => {
  const [rows, setRow] = useState<any[]>([]);

  useEffect(() => {
    if (selectedItem?.jobs) {
      setRow([...selectedItem.jobs]);
    }
  }, [selectedItem]);

  const columns = [
    {
      key: "created_time",
      name: "Date",
      width: "20%",
      resizable: true,
      renderCell: (props: any) => {
        if (!props.row.created_time) return "N/A";
        try {
          const date = parseISO(props.row.created_time);
          return format(date, "MM/dd/yyyy");
        } catch (error) {
          return "Invalid Date";
        }
      },
    },
    {
      key: "job_type",
      name: "Type",
      resizable: true,
      width: "20%",
      renderCell: (props: any) => {
        return props.row.job_type;
      },
    },
    {
      key: "filenames",
      name: "Files",
      resizable: true,
      width: "20%",
      renderCell: (props: { row: Record<string, any> }) => {
        return props.row.filenames?.join(", ");
      },
    },
    {
      key: "processing_status",
      name: "Status",
      width: "20%",
      resizable: true,
      renderCell: (props: RenderCellProps) => {
        if (props.row.processing_status === "F") {
          return (
            <PingLucideIcon style={{ color: "red" }} iconName="TriangleAlert" />
          );
        }
        return props.row.processing_status === "I" ? (
          <ProgressBar percentage={props.row.processing_pct_complete} />
        ) : (
          DOCUMENT_PROCESSING_STATUS?.[props.row.processing_status]
        );
      },
    },
    {
      key: "processing_last_message",
      name: "Last Message",
      resizable: true,
      width: "20%",
      renderCell: (props: any) => {
        return props.row.processing_last_message;
      },
    },
  ];

  return (
    <>
      <PingDataGrid
        className="PVAttachmentTable__DataGrid"
        columns={columns}
        rows={rows}
        rowClass={(row: any) => "PVAttachmentTable__DataGrid__Row"}
        rowKeyGetter={(row: any) => row.job_id || Math.random()}
        rowHeight={30}
        headerRowHeight={30}
        style={{ overflow: "auto" }}
      />
    </>
  );
};

export default PVJobsTable;
